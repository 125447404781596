import React from "react";
import { colomnKeys } from "../../OfferingVariationsList.constants";
import { TypeField } from "./TypeField";
import moduleStyle from "./Columns.module.css";
import { VARIANT_TYPE_IDS } from "features/OfferingVariations/OfferingVariations.constants";

export const TitleField = (record) => {
  return (
    <div className={`w-100 d-flex align-items-center justify-content-between`}>
      <div className={`d-flex align-items-center`}>
        <img
          src="./assets/images/offering_variations/restart.png"
          alt="restart_icon"
        />
        <div className={`ml-1 ${moduleStyle.titleText}`}>
          {record[colomnKeys.title.key]}
        </div>
      </div>
      {record[colomnKeys.type.key] === VARIANT_TYPE_IDS.default && (
        <TypeField {...record} />
      )}
    </div>
  );
};
