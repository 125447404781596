import { colomnKeys } from "../../OfferingVariationsList.constants";
import React from "react";
import { OfferingVariationContext } from "features/OfferingVariations/pages/OfferingVariationsWrapper";
import { getSubscriptionPlanChoiceLabel } from "features/OfferingVariations/utils/OfferingVariations.utils";

export const SubscriptionPlanField = (record) => {
  const { offeringDetails } = React.useContext(OfferingVariationContext);
  return (
    <div>
      {record[colomnKeys.subscription_plan.magnitude]}&nbsp;
      {getSubscriptionPlanChoiceLabel({
        offeringDetails,
        selectedRecord: record,
      })}
    </div>
  );
};
