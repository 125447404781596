import React from "react";
import { colomnKeys } from "../../OfferingVariationsList.constants";
import { VARIANT_TYPE } from "features/OfferingVariations/OfferingVariations.constants";

export const TypeField = (record) => {
  const variantType = VARIANT_TYPE[record[colomnKeys.type.key]];
  return (
    <div className={`${variantType.class} fit_content`}>
      {variantType.label}
    </div>
  );
};
