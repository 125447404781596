import React from "react";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import { OfferingVariationsList } from "./pages/OfferingVariationsList";
import { OfferingVariationsWrapper } from "./pages/OfferingVariationsWrapper";

export const OfferingVariations = (props) => {
  useAppLayoutControl({ values: { showBottomNav: false } });

  return (
    <OfferingVariationsWrapper {...props}>
      <OfferingVariationsList {...props} />
    </OfferingVariationsWrapper>
  );
};
