import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

export const ShowTourSuggestionBackdrop = ({
  backdropClass = "",
  showBackdrop = false,
}) => {
  const { showTourSuggestion } = useSelector((state) => state.uiLayout);
  return (
    (showTourSuggestion || showBackdrop) && (
      <div className={classnames("backdrop", backdropClass)}></div>
    )
  );
};
