// if: lines of code > 300
// then: breakdown this file and move to src/features/RecordedContent/constants folder

export const alertMessage = {
  text: "Please make all changes at once before saving. Once saved, we will be processing the updates, temporarily blocking further edits.",
  tooltipText:
    `Due to the large number of customers in this course, updating everyone takes time and resources. By doing updates all at once, allows for efficient management and ensures a smooth experience for everyone.<br><br>` +
    `Thanks for your cooperation, and know that we're always improving our system for both creators and learners.<br><br>`,
};

export const VDOCIPHER_VIDEO_MAX_FILE_SIZE_IN_GB = 5;
export const WISTIA_VIDEO_MAX_FILE_SIZE_IN_GB = 8;

export const RECORDED_CONTENT_UNLOCKING_MUTATION_INFO =
  "Changes apply only to new customers; existing customers keep their original unlocking settings from the time of purchase.";
