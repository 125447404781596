import { ActionField } from "./Components/Columns/ActionField";
import { AmountField } from "./Components/Columns/AmountField";
import { SubscriptionPlanField } from "./Components/Columns/SubscriptionPlanField";
import { TitleField } from "./Components/Columns/TitleField";
import { TypeField } from "./Components/Columns/TypeField";
import { colomnKeys } from "./OfferingVariationsList.constants";

export const getTableConfig = () => [
  {
    field: colomnKeys.title.key,
    headerName: colomnKeys.title.label,
    renderColumn: TitleField,
  },
  {
    field: "",
    headerName: colomnKeys.price.label,
    emptyField: "N/A",
    sortable: false,
    valueFormatter: AmountField,
  },
  {
    field: "",
    headerName: colomnKeys.subscription_plan.label,
    emptyField: "N/A",
    sortable: false,
    valueFormatter: SubscriptionPlanField,
  },
  {
    field: "",
    headerName: colomnKeys.type.label,
    emptyField: "N/A",
    sortable: false,
    valueFormatter: TypeField,
    hidden: true,
  },
  {
    field: "",
    headerName: colomnKeys.action.label,
    sortable: false,
    hidden: true,
    fixed: "right",
    valueFormatter: ActionField,
  },
];
