import { useEffect, useState } from "react";
import { fetchExams } from "schedule-v2/RecordedContent/components/AddCertificate/addCertificate.ApiCalls";
import { logError } from "utils/error";

const useExamsList = ({ listingUuid, scoringType, isReportPublished }) => {
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleFetchExams = () => {
      const abortController = new AbortController();

      const handleError = (error) => {
        logError({
          error,
          occuredAt: "src/features/RecordedContent/utils/useExamsList.js",
          when: "calling fetchExams",
          severity: "blocker",
        });
      };

      const handleFinally = () => {
        setLoading(false);
      };

      const handleAbort = () => {
        abortController.abort();
      };

      setLoading(true);

      fetchExams({
        listingUuid,
        scoringType,
        isReportPublished,
        abortSignal: abortController.signal,
      })
        .then(setExams)
        .catch(handleError)
        .finally(handleFinally);

      return handleAbort;
    };

    return handleFetchExams();
  }, []);

  return { loading, exams };
};

export default useExamsList;
