import { useEffect, useState } from "react";
import { getWhatsNextInfo } from "features/Onboarding/modules/WhatsNext/utils/whatsNext.utils";
import { WHATS_NEXT_CUSTOM_EVENT } from "../WhatsNext.constants";

/**
 * useWhatsNextLocalStorageChange is a custom hook that listens for changes
 * to the local storage associated with the "WhatsNext" information. It returns
 * the updated "WhatsNext" data whenever a custom "whatsNextDataUpdate" event is 
 * triggered. The data is initially retrieved from `getWhatsNextInfo()`.
 * Currently triggered from : features/Onboarding/modules/WhatsNext/utils/whatsNext.utils.js

 * The hook adds an event listener for the 
" event, and the listener updates the state with the 
 * new "WhatsNext" information when the event occurs.
 * Ref : 
 * 1 https://javascript.info/dispatch-events
 * 2 https://developer.mozilla.org/en-US/docs/Web/API/Event/Event#browser_compatibility

 * @returns {object} - The latest "WhatsNext" data from local storage.
 */

export const useWhatsNextLocalStorageChange = () => {
  const [value, setValue] = useState(() => getWhatsNextInfo());

  useEffect(() => {
    const handleStorageChange = () => {
      setValue(() => getWhatsNextInfo()); // Update state if localStorage changes
    };

    window.addEventListener(WHATS_NEXT_CUSTOM_EVENT, handleStorageChange);

    return () => {
      window.removeEventListener(WHATS_NEXT_CUSTOM_EVENT, handleStorageChange);
    };
  }, []);

  return value;
};
