import React from "react";
import moduleStyle from "./EnableVariation.module.css";
import Tooltip from "ui/modules/Tooltip";
import ExlySwitch from "common/form/ExlySwitch";
import { OfferingVariationContext } from "features/OfferingVariations/pages/OfferingVariationsWrapper";

export const EnableVariation = () => {
  const { isEnabled, onEnableVariationSwitchChange } = React.useContext(
    OfferingVariationContext
  );

  return (
    <div
      className={`w-100 ${moduleStyle.container} d-flex align-items-center px-3 py-2 justify-content-between justify-content-md-start`}
    >
      <div className={`d-flex align-items-center`}>
        <div className={`${moduleStyle.title}`}>Enable Variations</div>
        <Tooltip
          infoIconClassName={`ml-1 ${moduleStyle.tooltip}`}
          text={
            "Enabling this will make all variations visible on the offering’s event page when the customer is making a booking. All variation will be displayed in a dropdown and customers can choose a variation as per their interest and requirements."
          }
        />
      </div>
      <ExlySwitch
        color="success"
        input={{ value: isEnabled, onChange: onEnableVariationSwitchChange }}
      />
    </div>
  );
};
