import ExlyModal from "common/Components/ExlyModal";
import React from "react";
import {
  CREATE_VARIATION_CTA_TEXT,
  EDIT_VARIATION_CTA_TEXT,
} from "features/OfferingVariations/OfferingVariations.constants";
import { ExlyInput } from "common/form";
import { FORM_FIELDS } from "./AddVariation.constants";
import ExlyMoneyInput from "common/form/ExlyMoneyInput";
import constants from "constants/constants";
import ExlyCheckbox from "common/form/ExlyCheckbox";
import moduleStyle from "./AddVariation.module.css";
import HelpOutlineIcon from "@material-ui/icons/InfoOutlined";
import { is_empty } from "utils/validations";
import { OfferingVariationContext } from "features/OfferingVariations/pages/OfferingVariationsWrapper";
import { getSubscriptionPlanChoiceLabel } from "features/OfferingVariations/utils/OfferingVariations.utils";

export const AddVariation = () => {
  const {
    showAddVariation,
    handleAddVariation,
    formData,
    updateFormData,
    processing,
    showInternationalPrice,
    handleToggleInternationalSwitch,
    updateVariation,
    selectedVariation,
    errorObject,
    offeringDetails,
    closeModal,
  } = React.useContext(OfferingVariationContext);

  const getCtaText = () => {
    if (processing) return "...";
    return is_empty(selectedVariation)
      ? CREATE_VARIATION_CTA_TEXT
      : EDIT_VARIATION_CTA_TEXT;
  };

  return (
    <ExlyModal
      title={`${is_empty(selectedVariation) ? "New" : "Edit"} Variation`}
      open={showAddVariation}
      onClose={closeModal}
      primaryBtnText={getCtaText()}
      onPrimaryBtnClick={
        is_empty(selectedVariation) ? handleAddVariation : updateVariation
      }
    >
      <>
        <div className={`my-2`}>
          <ExlyInput
            meta={{
              touched: errorObject?.[FORM_FIELDS.title.key],
              error: errorObject?.[FORM_FIELDS.title.key],
            }}
            placeholder={FORM_FIELDS.title.placeHolder}
            fieldLabel={{ text: FORM_FIELDS.title.label, required: true }}
            fullWidth
            value={formData[FORM_FIELDS.title.key]}
            onChange={(value) => {
              updateFormData({ key: FORM_FIELDS.title.key, value });
            }}
          />
        </div>
        <div className={`my-2`}>
          <ExlyMoneyInput
            meta={{
              touched: errorObject?.[FORM_FIELDS.domestic_price.key],
              error: errorObject?.[FORM_FIELDS.domestic_price.key],
            }}
            fieldLabel={{
              text: FORM_FIELDS.domestic_price.label,
              required: true,
            }}
            type="number"
            fullWidth
            symbol={constants.inr_currency}
            value={formData[FORM_FIELDS.domestic_price.key]}
            onChange={(value) => {
              updateFormData({
                key: FORM_FIELDS.domestic_price.key,
                value: parseFloat(value),
              });
            }}
          />
        </div>

        <div className={`my-2`}>
          <ExlyCheckbox
            checked={showInternationalPrice}
            label={FORM_FIELDS.show_international_price.label}
            size="large"
            labelClassName={`${moduleStyle.checkbox_label}`}
            onChange={handleToggleInternationalSwitch}
            checkboxClassName={moduleStyle.checkbox}
          />
        </div>
        {showInternationalPrice && (
          <div className={`my-1`}>
            <ExlyMoneyInput
              meta={{
                touched: errorObject?.[FORM_FIELDS.international_price.key],
                error: errorObject?.[FORM_FIELDS.international_price.key],
              }}
              fieldLabel={{
                text: FORM_FIELDS.international_price.label,
                required: true,
              }}
              type="number"
              fullWidth
              symbol={constants.usd_currency}
              value={formData[FORM_FIELDS.international_price.key]}
              onChange={(value) => {
                updateFormData({
                  key: FORM_FIELDS.international_price.key,
                  value: parseFloat(value),
                });
              }}
            />
          </div>
        )}

        <div className={`my-2 row no-gutters`}>
          <div className={`mb-1 col-12`}>
            <span className={`${moduleStyle.labelField}`}>
              {FORM_FIELDS.subscription_plan_magnitude.label}
            </span>
            <span className={`text-danger`}>*</span>
          </div>
          <div className={`col-4`}>
            <ExlyInput
              meta={{
                touched:
                  errorObject?.[FORM_FIELDS.subscription_plan_magnitude.key],
                error:
                  errorObject?.[FORM_FIELDS.subscription_plan_magnitude.key],
              }}
              type="number"
              placeholder={FORM_FIELDS.subscription_plan_magnitude.placeHolder}
              value={formData[FORM_FIELDS.subscription_plan_magnitude.key]}
              onChange={(value) => {
                updateFormData({
                  key: FORM_FIELDS.subscription_plan_magnitude.key,
                  value: parseInt(value),
                });
              }}
            />
          </div>
          <div className={`offset-1 col-7`}>
            <ExlyInput
              fullWidth
              disabled
              //   fieldLabel={{ text: FORM_FIELDS.title.label, required: true }}
              value={getSubscriptionPlanChoiceLabel({
                offeringDetails,
                selectedRecord: formData,
              })}
            />
          </div>

          <div
            className={`${moduleStyle.infoContainer} p-1 d-flex align-items-center my-2 w-100`}
          >
            <HelpOutlineIcon className={`mr-2 ${moduleStyle.infoIcon}`} />
            <div className={`${moduleStyle.infoText}`}>
              Duration can&apos;t exceed default variation duration.
            </div>
          </div>
        </div>
      </>
    </ExlyModal>
  );
};
