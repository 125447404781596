import { useOfferings } from "hooks/useOfferings";
import React from "react";
import { isInteger, is_empty } from "utils/validations";
import {
  CREATE_VARIATION_CTA_TEXT,
  VARIANT_TYPE_IDS,
} from "../OfferingVariations.constants";
import { dataProvider } from "data";
import {
  CREATE_OFFERING_VARIATION,
  UPDATE_OFFERING_VARIATION,
} from "../OfferingVariations.apis";
import { apiMethods } from "data/api.constants";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";
import { useRefresh } from "react-admin";
import { logError } from "utils/error";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import {
  FORM_FIELDS,
  DEFAULT_FORM_DATA,
  FORM_VALIDATIONS,
} from "../pages/OfferingVariationsList/Components/AddVariation/AddVariation.constants";

export const useOfferingVariations = ({ offeringUuid }) => {
  const { notify } = useNotifications();
  const refetch = useRefresh();
  const { fetchOfferingDetails, updateOffering } = useOfferings();

  const [isEnabled, setIsEnabled] = React.useState(false); // offering variation switch ; if false click of everyaction will be disabled in the page
  const [offeringDetails, setOfferingDetails] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [showAddVariation, setShowAddVariation] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedVariation, setSelectedVariation] = React.useState(null);
  const [showInternationalPrice, setShowInternationalPrice] =
    React.useState(false);
  const [formData, setFormData] = React.useState({
    ...DEFAULT_FORM_DATA,
  });
  const [errorObject, setErrorObject] = React.useState({});

  const getListingDetails = async ({ forceFetch } = {}) => {
    if (processing) return;
    setProcessing(true);
    const response = await fetchOfferingDetails({
      offeringUuid,
      forceFetch,
    });
    if (!is_empty(response)) {
      setOfferingDetails(response);
      setIsEnabled(response.is_offering_variants_enabled);
      setFormData({
        ...formData,
        [FORM_FIELDS.subscription_plan_choice.key]: response?.course_end_choice,
      });
    }
    setProcessing(false);
  };

  const footerProps = {
    primaryBtnProps: {
      disabled: !isEnabled,
    },
    primarybtn: CREATE_VARIATION_CTA_TEXT,
    primaryClick: () => setShowAddVariation(true),
  };

  const updateFormData = ({ key, value }) => {
    let tempErrorObject = { ...errorObject };
    setFormData({ ...formData, [key]: value });
    delete tempErrorObject[key];
    setErrorObject({ ...tempErrorObject });
  };

  const validateForm = (form = formData) => {
    let errMsg = "";
    let tempErrorObject = { ...errorObject };
    for (let key in form) {
      if (
        [
          FORM_FIELDS.international_price.key,
          FORM_FIELDS.domestic_price.key,
        ].includes(key)
      ) {
        if (!isInteger(form[key])) {
          errMsg = "Invalid from data!";
          tempErrorObject[key] = "Required";
        }
        continue;
      } else if (is_empty(form[key])) {
        errMsg = "Invalid from data!";
        tempErrorObject[key] = "Required";
        continue;
      } else if (
        selectedVariation?.variant_type !== VARIANT_TYPE_IDS.default &&
        key === FORM_FIELDS.subscription_plan_magnitude.key &&
        form[key] >= offeringDetails.course_end_magnitude
      ) {
        errMsg =
          "Duration must be less than the duration of your default variation";
        tempErrorObject[key] = "Invalid";
        continue;
      } else if (
        key === FORM_FIELDS.title.key &&
        form[key].length > FORM_VALIDATIONS[FORM_FIELDS.title.key].maxLength
      ) {
        errMsg = "Invalid from data!";
        tempErrorObject[key] = `Max ${
          FORM_VALIDATIONS[FORM_FIELDS.title.key].maxLength
        } characters`;
        continue;
      } else if (
        key === FORM_FIELDS.title.key &&
        form[key].length < FORM_VALIDATIONS[FORM_FIELDS.title.key].minLength
      ) {
        errMsg = "Invalid from data!";
        tempErrorObject[key] = `Min ${
          FORM_VALIDATIONS[FORM_FIELDS.title.key].minLength
        } characters`;
        continue;
      }
    }
    setErrorObject(tempErrorObject);
    return errMsg;
  };

  const handleAddVariation = async () => {
    try {
      if (processing) return;
      setProcessing(true);
      let payload = { ...formData, listing_uuid: offeringDetails?.uuid };
      if (!showInternationalPrice) {
        delete payload[FORM_FIELDS.international_price.key];
      }
      const errorsCheck = validateForm();
      if (!is_empty(errorsCheck)) {
        throw new Error(errorsCheck);
      }
      const response = await dataProvider.custom_request(
        CREATE_OFFERING_VARIATION,
        apiMethods.POST,
        payload
      );
      notify(response?.message, notification_color_keys.success);
      if (isRequestSuccessful(response.status)) {
        resetStates();
      }
    } catch (error) {
      logError({
        error,
        occuredAt:
          "src/features/OfferingVariations/pages/OfferingVariationsList/Components/AddVariation/useAddVariation.js",
        when: "on handleAddVariation",
      });
      notify(
        error?.body?.message ?? error?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    } finally {
      setProcessing(false);
    }
  };

  const updateVariation = async ({
    offering_variation_uuid = selectedVariation.uuid,
    updated_values = formData,
  }) => {
    try {
      if (processing) return;
      setProcessing(true);
      const payload = { uuid: offering_variation_uuid, updated_values };
      if (updated_values.is_active !== false) {
        // validate only if variation is updated not deleted
        const errorsCheck = validateForm();
        if (!is_empty(errorsCheck)) {
          throw new Error(errorsCheck);
        }
      }

      const response = await dataProvider.custom_request(
        UPDATE_OFFERING_VARIATION,
        apiMethods.POST,
        payload
      );
      notify(response?.message, notification_color_keys.success);
      if (isRequestSuccessful(response.status)) {
        resetStates();
        if (selectedVariation?.variant_type === VARIANT_TYPE_IDS.default) {
          await getListingDetails({ forceFetch: true });
        }
      }
    } catch (error) {
      logError({
        error,
        occuredAt:
          "src/features/OfferingVariations/pages/OfferingVariationsList/Components/AddVariation/useAddVariation.js",
        when: "on handleAddVariation",
      });

      notify(
        error?.body?.message ?? error?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    } finally {
      setProcessing(false);
    }
  };

  const resetStates = (dontRefetch = false) => {
    setShowAddVariation(false);
    setShowDeleteModal(false);
    setShowInternationalPrice(false);
    setSelectedVariation(null);
    setFormData({
      ...DEFAULT_FORM_DATA,
      [FORM_FIELDS.subscription_plan_choice.key]:
        offeringDetails?.course_end_choice,
    });
    setErrorObject({});
    if (!dontRefetch) {
      refetch();
    }
  };

  const deleteVariation = () => {
    if (is_empty(selectedVariation)) {
      return notify(SOMETHING_WENT_WRONG, notification_color_keys.error);
    }
    updateVariation({
      updated_values: { is_active: false },
    });
  };

  const handleEditVariation = (record) => {
    if (!isEnabled) return;
    setSelectedVariation(record);
    let tempFormData = { ...formData };
    for (let key in record) {
      if (key in tempFormData) {
        tempFormData[key] = record[key];
      }
    }
    if (!is_empty(record[FORM_FIELDS.international_price.key])) {
      tempFormData[FORM_FIELDS.international_price.key] =
        record[FORM_FIELDS.international_price.key];
      setShowInternationalPrice(true);
    }
    setFormData(tempFormData);
    setShowAddVariation(true);
  };

  const handleToggleInternationalSwitch = (checked) => {
    const tempFormData = { ...formData };
    if (checked) {
      tempFormData[FORM_FIELDS.international_price.key] = "";
    } else {
      delete tempFormData[FORM_FIELDS.international_price.key];
      if (errorObject[FORM_FIELDS.international_price.key]) {
        setErrorObject((tempErrorObject) => {
          delete tempErrorObject[FORM_FIELDS.international_price.key];
          return tempErrorObject;
        });
      }
    }
    setShowInternationalPrice(checked);
    setFormData(tempFormData);
  };

  const closeModal = () => resetStates(true);

  const onEnableVariationSwitchChange = (checked) => {
    if (processing) return;
    setProcessing(true);
    updateOffering({
      offeringUuid: offeringDetails.uuid,
      updatedValues: {
        is_offering_variants_enabled: checked,
      },
      onEnd: () => setProcessing(false),
      onSuccess: (response) => {
        if (checked) {
          refetch();
        }
        setIsEnabled(checked);
        notify(response?.message, notification_color_keys.success);
      },
      onError: (error) => {
        logError({
          error,
          occuredAt:
            "src/features/OfferingVariations/utils/useOfferingVariations.js",
          when: "onEnableVariationSwitchChange",
        });
        notify(
          error?.body?.message ?? SOMETHING_WENT_WRONG,
          notification_color_keys.error
        );
      },
    });
  };

  const provider = {
    isEnabled,
    setIsEnabled,
    offeringDetails,
    showAddVariation,
    deleteVariation,
    showDeleteModal,
    setSelectedVariation,
    handleToggleInternationalSwitch,
    updateFormData,
    handleAddVariation,
    formData,
    showInternationalPrice,
    processing,
    handleEditVariation,
    updateVariation,
    errorObject,
    selectedVariation,
    closeModal,
    setShowDeleteModal,
    onEnableVariationSwitchChange,
    footerProps,
    getListingDetails,
    setShowAddVariation,
  };

  return {
    provider,
  };
};
