import React from "react";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import { colomnKeys } from "../../OfferingVariationsList.constants";
import constants from "constants/constants";

export const AmountField = (record) => {
  return (
    <div>
      {getUserCurrencySymbol()}&nbsp;{record[colomnKeys.price.domestic_price]}
      {record[colomnKeys.price.international_price] ? (
        <span>
          {" "}
          or {constants.usd_currency}
          {record[colomnKeys.price.international_price]}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
