import React from "react";
import { useOfferingVariations } from "features/OfferingVariations/utils/useOfferingVariations";

export const OfferingVariationContext = React.createContext();

export const OfferingVariationsWrapper = (props) => {
  const { children, match } = props;
  const offeringUuid = match.params.offeringUuid;
  const { provider } = useOfferingVariations({ offeringUuid });
  const { getListingDetails } = provider;

  React.useEffect(() => {
    getListingDetails();
  }, []);

  return (
    <OfferingVariationContext.Provider value={{ ...provider, offeringUuid }}>
      {children}
    </OfferingVariationContext.Provider>
  );
};
