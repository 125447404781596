export const PAYMENT_STATUS_KEYS = {
  INITIATED: 1,
  SUCCESS: 2,
  PROCESSING: 7,
  FAILED: 8,
};

export const PAYMENT_STATUS = {
  [PAYMENT_STATUS_KEYS.INITIATED]: {
    slug: "INITIATED",
    title: "Payment initiated",
    color: "rgba(255, 221, 153, 1)",
    background_color: "rgba(255, 221, 153, 0.3)",
    value: PAYMENT_STATUS_KEYS.INITIATED,
  },
  [PAYMENT_STATUS_KEYS.SUCCESS]: {
    slug: "SUCCESS",
    title: "Payment successful",
    color: "rgba(54, 179, 126, 1)",
    background_color: "rgba(54, 179, 126, 0.3)",
    value: PAYMENT_STATUS_KEYS.SUCCESS,
  },
  [PAYMENT_STATUS_KEYS.PROCESSING]: {
    slug: "PROCESSING",
    title: "Payment processing",
    color: "rgba(255, 221, 153, 1)",
    background_color: "rgba(255, 221, 153, 0.3)",
    value: PAYMENT_STATUS_KEYS.PROCESSING,
  },
  [PAYMENT_STATUS_KEYS.FAILED]: {
    slug: "FAILED",
    title: "Payment failed",
    color: "rgb(223, 99, 93, 1)",
    background_color: "rgb(223, 99, 93, 0.3)",
    value: PAYMENT_STATUS_KEYS.FAILED,
  },
};
