export const FORM_FIELDS = {
  title: {
    label: "Title",
    key: "title",
    placeHolder: "Enter variation title (eg: 3 month plan)",
  },
  domestic_price: {
    label: "Price (INR)",
    key: "domestic_price",
  },
  show_international_price: {
    label: "I want to set a different price for international customers",
    key: "show_international_price",
  },
  international_price: {
    label: "Price (USD)",
    key: "international_price",
  },
  subscription_plan_magnitude: {
    placeHolder: "eg: 3",
    label: "Subscription plan",
    key: "subscription_plan_magnitude",
  },
  subscription_plan_choice: {
    key: "subscription_plan_choice",
  },
};

export const DEFAULT_FORM_DATA = {
  [FORM_FIELDS.title.key]: "",
  [FORM_FIELDS.domestic_price.key]: "",
  // [FORM_FIELDS.international_price.key]: "",
  [FORM_FIELDS.subscription_plan_magnitude.key]: "",
};

export const FORM_VALIDATIONS = {
  [FORM_FIELDS.title.key]: {
    minLength: 3,
    maxLength: 100,
  },
};
