import React from "react";
import moduleStyle from "./Columns.module.css";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import { VARIANT_TYPE_IDS } from "features/OfferingVariations/OfferingVariations.constants";
import { OfferingVariationContext } from "features/OfferingVariations/pages/OfferingVariationsWrapper";

export const ActionField = (record) => {
  const {
    setSelectedVariation,
    setShowDeleteModal,
    handleEditVariation,
    isEnabled,
  } = React.useContext(OfferingVariationContext);

  return (
    <div className={`d-flex align-items-center`}>
      <EditIcon
        onClick={() => {
          handleEditVariation(record);
        }}
        className={`${moduleStyle.editIcon} mr-3`}
      />
      {record.variant_type === VARIANT_TYPE_IDS.others && (
        <DeleteIcon
          onClick={() => {
            if (!isEnabled) return;
            setSelectedVariation(record);
            setShowDeleteModal(true);
          }}
          className={`${moduleStyle.deleteIcon}`}
        />
      )}
    </div>
  );
};
